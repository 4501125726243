var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('WeekSwiper'), _vm.isLoading && !_vm.renderListLength ? _c('b-alert', {
    staticClass: "mb-0 p-1",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.searching')) + "... ")]) : !_vm.isLoading && !_vm.renderListLength ? _c('b-alert', {
    staticClass: "mb-0 p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.notBookingFound')) + " ")]) : _vm._e(), _c('div', [_c('div', {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    attrs: {
      "infinite-scroll-distance": "800"
    }
  }, _vm._l(_vm.infiniteItems, function (tripItem) {
    var _vm$selected;
    return _c('div', {
      key: tripItem.id
    }, [_c('BCard', {
      staticClass: "px-1 py-50",
      style: "".concat(_vm.selected && _vm.selected.id === tripItem.id ? 'border: 2px solid #efad02;' : 'border: 2px solid transparent;'),
      attrs: {
        "no-body": ""
      }
    }, [_c('DetailPriceCollapse', {
      key: "DetailPriceCollapse-".concat(tripItem.id),
      attrs: {
        "trip-item": tripItem,
        "is-checked": ((_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.id) === tripItem.id
      }
    }), _c('Trips', {
      key: "Trips-".concat(tripItem.id),
      attrs: {
        "trip-item": tripItem,
        "selected-trip": _vm.selected
      }
    })], 1)], 1);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }